import React, { useState } from 'react';
// import { Link } from 'gatsby';
// import PropTypes from 'prop-types';
// import { Link } from 'gatsby';
import Modal from '../Modal/Modal';
import styles from './ModalUpdate.module.scss';

// const getFromStorage = () => {
//   if (typeof window !== 'undefined') {
//     const storage = localStorage.getItem('easterMenu');
//     return !!storage;
//   }
//   return false;
// };

// const setToStorage = () => {
//   if (typeof window !== 'undefined') {
//     localStorage.setItem('easterMenu', 'true');
//   }
// };

// if (typeof window !== 'undefined') {
//   window.modalIsOpen = true;
// }

const ModalUpdate = () => {
  const [isOpen, setIsOpen] = useState(true);
  // const [isOpen, setIsOpen] = useState(!getFromStorage());
  // const closeHandler = () => {
  //   if (typeof window !== 'undefined') {
  //     window.modalIsOpen = false;
  //   }
  //   setIsOpen(false);
  //   setToStorage();
  // };

  return (
    <Modal
      isOpen={isOpen}
      closeHandler={() => setIsOpen(false)}
      title="Wir machen Urlaub!"
      className={styles.christmasModal}
    >
      {/* <ul className="mb--xs">
        <li><strong>Aushilfe im Ladenlokal</strong></li>
        <li><strong>Aushilfe im Catering</strong></li>
      </ul> */}
      <p className={styles.text}>
        Unser Ladenlokal bleibt bis zum 27.11.2024 geschlossen. Ab dem 28.11. sind wir wieder für Sie da.
      </p>
    </Modal>
  );
};

ModalUpdate.propTypes = {};

ModalUpdate.defaultProps = {};

export default ModalUpdate;
